export default {
  getHomeUrl: function() {
    return process.env.VUE_APP_HOME_API_BASE_URL || 'http://localhost/new-fiss/fiss:8080'
  },
  getGuestUrl: function (path) {
    const GUEST_BASE_URL = process.env.VUE_APP_GUEST_API_BASE_URL || 'http://localhost/new-fiss/guest-api'
    const url = GUEST_BASE_URL + path

    return url
  },
  getFarmerAuthenUrl: function (path) {
    const AUTH_BASE_URL = process.env.VUE_APP_FARMERAUTHEN_API_BASE_URL || 'http://localhost:8007'
    const url = AUTH_BASE_URL + path

    return url
  },
  getNotificationUrl: function (path) {
    const NOTICE_BASE_URL = process.env.VUE_APP_NOTIFICATION_API_BASE_URL || 'http://localhost:8003'
    const url = NOTICE_BASE_URL + path

    return url
  },
  getFarmerEnumerationtUrl: function (path) {
    const ENUMERATION_BASE_URL = process.env.VUE_APP_ENUMERATION_API_BASE_URL || 'http://localhost:8008'
    const url = ENUMERATION_BASE_URL + path
    
    return url
  },
  postFetch: function (url,params) {
    return this.makeRequest(url,'POST',params)
  },
  postFetchWithToken: function (url,params,token) {
    return this.makeRequest(url,'POST',params,token)
  },

  makeRequest: function (url, method, params = {},token) {
    const responseValue = {
      status: '',
      message: '',
      data: {}
    }

    const options = {
      headers: { 'Content-Type': 'application/json' ,
        'Accept': 'application/json' }
    }

    if ( token !== 'undefined' ) {
      options.headers = { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    } 
    
    if (method === 'GET') {
      options.method = 'GET'
      if (params !== null)
        url += '?' + (new URLSearchParams(params)).toString()
    }

    if (method === 'POST') {
      options.body = JSON.stringify(params)
      options.method = 'POST'
    }

    return fetch(url, options)
      .then(async (response) => {
        const data = await response.json()
        //console.log('this is our response', response)

        responseValue['status'] = response.status
        responseValue['message'] = data.message
        responseValue['data'] = data.data

        //console.log('our response is ', responseValue)
        return responseValue
      })
      .catch((error) => {
        console.log(error)
        /*if (error instanceof SyntaxError) {
          // Unexpected token < in JSON
          console.log('There was a SyntaxError', error)
        } else {
          console.log('There was an error', error)
        }*/
        console.error(JSON.parse(error))
        responseValue['status'] = error.status
        responseValue['message'] = error
        responseValue['data'] = error

        //return responseValue

        return error
      })
  },
  setSessionItem (key, value) {
    localStorage.setItem(key, value)
  },
  setSessionJson (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getSessionItem (key) {
    return localStorage.getItem(key)
  },
  getSessionJson (key) {
    return JSON.parse(localStorage.getItem(key))
  },
  removeSessionJson (key) {
    localStorage.removeItem(key)
  },
  clearSession() {
    localStorage.clear()
  },
 
  scrollTo(data) {
    if (data === 'top') {
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }, 500)
    }
    if (data === 'bottom') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }
}